@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bar-item {
    color: white;
    padding-left: 40px;
    padding-right: 40px;
}

.bar-item:hover {
    color: #00ffff;
    transform: translateY(-2px);
}

.bar-item:hover .bar-item-text {
    visibility: visible;
}

.bar-item-text {
    color: white;
    padding-top: 0.5em;
    font-weight: 450;
}

.bar-item-container {
    font-size: 1em;
}

.social-container {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.social-container i {
    color: white;
    font-size: 3em;
}

.fa-github-alt {
    font-size: 3.3em !important;
}

.fa-keyboard {
    font-size: 3.3em !important;
}
/* Google Fonts */
.custom-container {
    color: white;
    padding-top: 80px;
}

.bar-item-container p {
    color: white;
    font-size: medium;
    visibility: hidden;
    padding-top: 10px;
    font-weight: 550 !important;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.bar-item:hover p {
    visibility: visible;
}

.type-writer-section {
    padding-top: 5em;
}

.social-bar {
    margin-top: 120px;
}

.secret-line {
    visibility: hidden;
}


/* Handle Screen Sizes */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .custom-container {
        padding-top: 30px;
    }

    h1 {
        font-size: 3em !important;
    }
      
    h2 {
        padding-top: 10px;
        font-size: 2em !important;
    }
    .intro-text-col {
        padding-top: 30px;
    }

    .profileImage {
        width: 250px;
        border-radius: 15px;
    }

    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 20px !important;
        text-align: center;
        line-height: normal;
        margin: auto;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
    .profileImage {
        width: 350px;
        border-radius: 15px;
    }

    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 24px;
        text-align: center;
        line-height: 30px;
        margin: auto;
    }
} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) {
    h1 {
        font-size: 7em !important;
    }
      
    h2 {
        font-size: 6em !important;
    }

    .custom-container {
        padding-top: 30px !important;
    }

    .profileImage {
        width: 350px;
        border-radius: 15px;
    }

    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 35px;
        text-align: center;
        line-height: 40px;
        margin: auto;
    }

    .intro-section {
        padding-top: 30px;
        font-size: 65%;
    }
}

@media only screen and (max-width: 745px) {
    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 28px;
        text-align: center;
        line-height: 40px;
        margin: auto;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .custom-container {
        padding-top: 40px !important;
    }

    h1 {
        font-size: 7em !important;
    }
      
    h2 {
        font-size: 6em !important;
    }

    .profileImage {
        width: 400px;
        display: block;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        border-radius: 15px;
    }

    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 45px;
        text-align: left;
        line-height: 48px;
        margin: auto;
    }

    .intro-section {
        padding-top: 4em;
        text-align: center;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .profile-image-col {
        padding-top: 100px;
    }

    h1 {
        font-size: 6em !important;
    }
      
    h2 {
        font-size: 6em !important;
    }
      
    .profileImage {
        width: 300px;
        display: block;
        margin-left: auto;
        margin-right: 60px;
        border-radius: 15px;
    }

    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 38px;
        text-align: left;
        margin-left: 0em;
        line-height: normal;
    }

    .intro-section {
        padding-top: 4em;
        text-align: left;
    }

    .social-bar {
        margin-top: 200px;
    }
}

@media only screen and (min-width: 1400px) {
    h1 {
        font-size: 6em !important;
    }
      
    h2 {
        font-size: 7em !important;
    }

    .profileImage {
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: 50px;
        border-radius: 15px;
    }
    
    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 40px;
        text-align: left;
        margin-left: 0em;
        line-height: 35px;
    }
}


/* Typing Animation */
/* Type Writer Effect */

.line-1 {
    width: 20em;
    border-right: 2px solid transparent;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    -webkit-animation: typewriter1 2s steps(60, end), blinkTextCursor .85s steps(60, end) 3, slidein 0s ease 0s forwards;
            animation: typewriter1 2s steps(60, end), blinkTextCursor .85s steps(60, end) 3, slidein 0s ease 0s forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    line-height: normal;
    margin-bottom: 10px;
}

.line-2 {
    width: 12em;
    border-right: 2px solid transparent;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    -webkit-animation: typewriter2 2s steps(60, end), blinkTextCursor .85s steps(60, end) 5, slidein 0s ease 0s forwards;
            animation: typewriter2 2s steps(60, end), blinkTextCursor .85s steps(60, end) 5, slidein 0s ease 0s forwards;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
}

/* Animation */ 

@-webkit-keyframes typewriter1 {
    from { 
        width: 0; 
    }
    to { 
        width: 20em; 
    }
} 

@keyframes typewriter1 {
    from { 
        width: 0; 
    }
    to { 
        width: 20em; 
    }
}

@-webkit-keyframes typewriter2 {
    from { 
        width: 0; 
    }
    to { 
        width: 12em; 
    }
}

@keyframes typewriter2 {
    from { 
        width: 0; 
    }
    to { 
        width: 12em; 
    }
}

@-webkit-keyframes blinkTextCursor {
    from { 
        border-right-color: rgba(255,255,255,.75); 
    }
    to { 
        border-right-color: transparent;
        }
}

@keyframes blinkTextCursor {
    from { 
        border-right-color: rgba(255,255,255,.75); 
    }
    to { 
        border-right-color: transparent;
        }
}

@-webkit-keyframes slidein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slidein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.App {
  text-align: center;
  background-color: black;
}

body {
  color: white;
  background-color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.particles {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

h1 {
  margin-bottom: 0;
  font-size: 3em;
  font-family: 'Source Sans Pro', sans-serif;
}

h2 {
  font-weight: bold;
  font-size: 3.5em;
  margin: 0;
}

.typing-text {
  font-family: 'Anonymouse Pro', monospace !important;
}
/* Handling Screen Sizes */

@media only screen and (min-width: 1531px) {
    .profileImage {
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: 70px;
        border-radius: 15px;
    }

    .customColumn {
        border: 3px dotted blue;
        justify-content: center;
    }

    .typing-text {
        font-size: 45px;
        text-align: left;
        margin-left: 0em;
        line-height: 48px;
    }
}

@media only screen and (max-width: 1530px) {
    .profileImage {
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: 70px;
        border-radius: 15px;
    }

    .typing-text {
        font-size: 40px;
        text-align: left;
        margin-left: 0em;
        line-height: 40px;
    }
}

@media only screen and (max-width: 1354px) {
    .profileImage {
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: 50px;
        border-radius: 15px;
    }
    
    .typing-text {
        font-size: 35px;
        text-align: left;
        margin-left: 0em;
        line-height: 35px;
    }
}

@media only screen and (max-width: 1200px) {
    .profileImage {
        width: 400px;
        display: block;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 15px;
        margin-top: 0;
    }

    .customColumn {
        border: 3px dotted blue;
        text-align: center;
    }

    .typing-text {
        font-size: 45px;
        text-align: center;
        line-height: 48px;
        margin: auto;
    }

    .intro-section {
        padding-top: 4em;
        text-align: center;
    }
}

@media screen and (max-width: 900px) {
    .profileImage {
        width: 350px !important;
    }

    .typing-text {
        font-size: 30px;
        text-align: center;
        line-height: 40px;
        margin: auto;
    }

    .intro-section {
        font-size: 75%;
    }
}

@media screen and (max-width: 615px) {
    .profileImage {
        width: 300px !important;
    }

    h1 {
      font-size: 34pt !important;
    }
    
    h2 {
      font-size: 34pt !important;
    }
  
    .typing-text {
        font-size: 26px !important;
        text-align: center;
        line-height: 38px;
        margin: auto;
    }
  }
  
  @media screen and (max-width: 575px) {
    .profileImage {
        width: 270px !important;
    }

    .typing-text {
        font-size: 36px !important;
        text-align: center;
        line-height: 52px;
        margin: auto;
    }
  
    h1 {
      font-size: 34pt !important;
    }
    
    h2 {
      font-size: 34pt !important;
    }
  }
  
  @media screen and (max-width: 445px) {
    .typing-text {
        font-size: 26px !important;
        text-align: center;
        line-height: 40px;
        margin: auto;
    }
  
    h1 {
      font-size: 34pt !important;
    }
    
    h2 {
      font-size: 30pt !important;
    }
  }

  @media screen and (max-width: 350px) {
    .typing-text {
        font-size: 26px !important;
        text-align: center;
        line-height: 40px;
        margin: auto;
    }
  
    h1 {
      font-size: 33pt !important;
    }
    
    h2 {
      font-size: 30pt !important;
    }
  }

  /* Social Bar Screen Handling */
  @media only screen and (min-width: 1000px) {
    .social-container i {
        font-size: 55px !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
}

@media only screen and (max-width: 1000px) {
    .social-container i {
        font-size: 50px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

@media only screen and (max-width: 800px) {
    .social-container i {
        font-size: 35pt !important;
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}

@media only screen and (max-width: 600px) {
    .social-container i {
        font-size: 30pt !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

@media only screen and (max-width: 375px) {
    .social-container i {
        font-size: 25pt !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

