.bar-item {
    color: white;
    padding-left: 40px;
    padding-right: 40px;
}

.bar-item:hover {
    color: #00ffff;
    transform: translateY(-2px);
}

.bar-item:hover .bar-item-text {
    visibility: visible;
}

.bar-item-text {
    color: white;
    padding-top: 0.5em;
    font-weight: 450;
}

.bar-item-container {
    font-size: 1em;
}

.social-container {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.social-container i {
    color: white;
    font-size: 3em;
}

.fa-github-alt {
    font-size: 3.3em !important;
}

.fa-keyboard {
    font-size: 3.3em !important;
}