.App {
  text-align: center;
  background-color: black;
}

body {
  color: white;
  background-color: black;
  user-select: none;
}

.particles {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

h1 {
  margin-bottom: 0;
  font-size: 3em;
  font-family: 'Source Sans Pro', sans-serif;
}

h2 {
  font-weight: bold;
  font-size: 3.5em;
  margin: 0;
}

.typing-text {
  font-family: 'Anonymouse Pro', monospace !important;
}