/* Google Fonts */

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;600&display=swap');
.custom-container {
    color: white;
    padding-top: 80px;
}

.bar-item-container p {
    color: white;
    font-size: medium;
    visibility: hidden;
    padding-top: 10px;
    font-weight: 550 !important;
    user-select: none;
}

.bar-item:hover p {
    visibility: visible;
}

.type-writer-section {
    padding-top: 5em;
}

.social-bar {
    margin-top: 120px;
}

.secret-line {
    visibility: hidden;
}


/* Handle Screen Sizes */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .custom-container {
        padding-top: 30px;
    }

    h1 {
        font-size: 3em !important;
    }
      
    h2 {
        padding-top: 10px;
        font-size: 2em !important;
    }
    .intro-text-col {
        padding-top: 30px;
    }

    .profileImage {
        width: 250px;
        border-radius: 15px;
    }

    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 20px !important;
        text-align: center;
        line-height: normal;
        margin: auto;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
    .profileImage {
        width: 350px;
        border-radius: 15px;
    }

    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 24px;
        text-align: center;
        line-height: 30px;
        margin: auto;
    }
} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) {
    h1 {
        font-size: 7em !important;
    }
      
    h2 {
        font-size: 6em !important;
    }

    .custom-container {
        padding-top: 30px !important;
    }

    .profileImage {
        width: 350px;
        border-radius: 15px;
    }

    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 35px;
        text-align: center;
        line-height: 40px;
        margin: auto;
    }

    .intro-section {
        padding-top: 30px;
        font-size: 65%;
    }
}

@media only screen and (max-width: 745px) {
    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 28px;
        text-align: center;
        line-height: 40px;
        margin: auto;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .custom-container {
        padding-top: 40px !important;
    }

    h1 {
        font-size: 7em !important;
    }
      
    h2 {
        font-size: 6em !important;
    }

    .profileImage {
        width: 400px;
        display: block;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        border-radius: 15px;
    }

    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 45px;
        text-align: left;
        line-height: 48px;
        margin: auto;
    }

    .intro-section {
        padding-top: 4em;
        text-align: center;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .profile-image-col {
        padding-top: 100px;
    }

    h1 {
        font-size: 6em !important;
    }
      
    h2 {
        font-size: 6em !important;
    }
      
    .profileImage {
        width: 300px;
        display: block;
        margin-left: auto;
        margin-right: 60px;
        border-radius: 15px;
    }

    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 38px;
        text-align: left;
        margin-left: 0em;
        line-height: normal;
    }

    .intro-section {
        padding-top: 4em;
        text-align: left;
    }

    .social-bar {
        margin-top: 200px;
    }
}

@media only screen and (min-width: 1400px) {
    h1 {
        font-size: 6em !important;
    }
      
    h2 {
        font-size: 7em !important;
    }

    .profileImage {
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: 50px;
        border-radius: 15px;
    }
    
    .typing-text {
        font-family: 'Source Code Pro', monospace;
        font-size: 40px;
        text-align: left;
        margin-left: 0em;
        line-height: 35px;
    }
}


/* Typing Animation */
/* Type Writer Effect */

.line-1 {
    width: 20em;
    border-right: 2px solid transparent;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    animation: typewriter1 2s steps(60, end), blinkTextCursor .85s steps(60, end) 3, slidein 0s ease 0s forwards;
    animation-delay: 2s;
    line-height: normal;
    margin-bottom: 10px;
}

.line-2 {
    width: 12em;
    border-right: 2px solid transparent;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    animation: typewriter2 2s steps(60, end), blinkTextCursor .85s steps(60, end) 5, slidein 0s ease 0s forwards;
    animation-delay: 4s;
}

/* Animation */ 

@keyframes typewriter1 {
    from { 
        width: 0; 
    }
    to { 
        width: 20em; 
    }
}

@keyframes typewriter2 {
    from { 
        width: 0; 
    }
    to { 
        width: 12em; 
    }
}

@keyframes blinkTextCursor {
    from { 
        border-right-color: rgba(255,255,255,.75); 
    }
    to { 
        border-right-color: transparent;
        }
}

@keyframes slidein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}